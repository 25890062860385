var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "header-hero bg_cover",
    staticStyle: {
      "background-image": "url(https://storeno.b-cdn.net/shipsen/10-2021/1633597619484.svg)"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-8"
  }, [_c('div', {
    staticClass: "header-hero-content text-center"
  }, [_vm._m(0), _c('h2', {
    staticClass: "header-title wow fadeInUp",
    staticStyle: {
      "visibility": "visible",
      "animation-duration": "1.3s",
      "animation-delay": "0.5s",
      "animation-name": "fadeInUp"
    },
    attrs: {
      "data-wow-duration": "1.3s",
      "data-wow-delay": "0.5s"
    }
  }, [_vm._v("Storeino Create Store")]), _c('p', {
    staticClass: "text wow fadeInUp",
    staticStyle: {
      "visibility": "visible",
      "animation-duration": "1.3s",
      "animation-delay": "0.8s",
      "animation-name": "fadeInUp"
    },
    attrs: {
      "data-wow-duration": "1.3s",
      "data-wow-delay": "0.8s"
    }
  }, [_vm._v("Avec les clients speedliv, vous bénéficierez d'une remise -15%")]), _c('div', {
    staticClass: "cardp"
  }, [_vm._m(1), _c('div', {
    staticClass: "copy-button"
  }, [_c('input', {
    attrs: {
      "id": "copyvalue",
      "type": "text",
      "readonly": "",
      "value": "CodInAfrica"
    }
  }), _c('button', {
    staticClass: "copybtn",
    on: {
      "click": _vm.copyIt
    }
  }, [_vm._v("COPIED")])])])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "header-hero-image text-center wow fadeIn",
    staticStyle: {
      "visibility": "visible",
      "animation-duration": "1.3s",
      "animation-delay": "1.4s",
      "animation-name": "fadeIn"
    },
    attrs: {
      "data-wow-duration": "1.3s",
      "data-wow-delay": "1.4s"
    }
  }, [_c('div', {
    staticClass: "line m-auto"
  }), _c('div', {
    attrs: {
      "id": "storeino_checkout",
      "data-lang": "FR",
      "data-domain": "storeino.com",
      "data-redirect": "https://business.storeino.com/manager/"
    }
  }), _c('script', {
    tag: "component",
    attrs: {
      "src": "https://storeino.com/checkout.js"
    }
  })], 1)])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "services-logo"
  }, [_c('img', {
    attrs: {
      "src": "https://storeno.b-cdn.net/shipsen/10-2021/1633356154211.png"
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mainp"
  }, [_c('div', {
    staticClass: "co-img"
  }, [_c('img', {
    attrs: {
      "src": "https://storeno.b-cdn.net/shipsen/10-2021/1633356552917.png",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "vertical"
  }), _c('div', {
    staticClass: "content"
  }, [_c('h2', [_vm._v("Storeino")]), _c('h1', [_vm._v("15% "), _c('span', [_vm._v("Coupon")])])])]);

}]

export { render, staticRenderFns }