<template>
   <section class="header-hero bg_cover" style="background-image: url(https://storeno.b-cdn.net/shipsen/10-2021/1633597619484.svg)">
      <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
            <div class="header-hero-content text-center">
            <div class="services-logo">
               <img src="https://storeno.b-cdn.net/shipsen/10-2021/1633356154211.png">
           </div>
            <h2 class="header-title wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.5s" style="visibility: visible; animation-duration: 1.3s; animation-delay: 0.5s; animation-name: fadeInUp;">Storeino Create Store</h2>
            <p class="text wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.8s" style="visibility: visible; animation-duration: 1.3s; animation-delay: 0.8s; animation-name: fadeInUp;">Avec les clients speedliv, vous bénéficierez d'une remise -15%</p>
            <div class="cardp">
                <div class="mainp">
                <div class="co-img">
                    <img src="https://storeno.b-cdn.net/shipsen/10-2021/1633356552917.png" alt="">
                </div>
                <div class="vertical"></div>
                <div class="content">
                    <h2>Storeino</h2>
                    <h1>15% <span>Coupon</span></h1>
                </div>
                </div>
                <div class="copy-button">
                <input id="copyvalue" type="text" readonly="" value="CodInAfrica">
                <button @click="copyIt" class="copybtn">COPIED</button>
                </div>
            </div>
            </div> 
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
            <div class="header-hero-image text-center wow fadeIn" data-wow-duration="1.3s" data-wow-delay="1.4s" style="visibility: visible; animation-duration: 1.3s; animation-delay: 1.4s; animation-name: fadeIn;">
            <div class="line m-auto"></div>
               <div id="storeino_checkout" data-lang="FR" data-domain="storeino.com" data-redirect="https://business.storeino.com/manager/"></div>
                <component :is="'script'" src="https://storeino.com/checkout.js"></component>
            </div> 
        </div>
      </div>
    </div>
   </section> 
</template>
<script>
export default {
    methods: {
        copyIt: function() {
            let copybtn = document.querySelector(".copybtn"); 
            let copyInput = document.querySelector('#copyvalue');
            copyInput.select();
            document.execCommand("copy");
            copybtn.textContent = "COPIED";
        },
    }
}    
</script>    
<style>
.line {
    background: -webkit-linear-gradient(#f7f3f2  0%, #e3e0e1  100%);
    background: -o-linear-gradient(#f7f3f2  0%, #e3e0e1  100%);
    background: linear-gradient(#f7f3f2  0%, #e3e0e1  100%);
    width: 150px;
    height: 5px;
}
#storeino_checkout .txtprm {
    font-size: 20px;
    font-weight: 700;
    color: #fff !important;
}
.services-logo img{
    width: 120px;
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 40%, 0);
    transform: translate3d(0, 40%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
.cardp::after {
    position: absolute;
    content: "";
    height: 40px;
    right: -20px;
    border-radius: 40px;
    z-index: 1;
    top: 70px;
    background-color: #3431ca;
    width: 40px;
}
.cardp::before {
    position: absolute;
    content: "";
    height: 40px;
    left: -20px;
    border-radius: 40px;
    z-index: 1;
    top: 70px;
    background-color: #3431ca;
    width: 40px;
}
.cardp {
    margin-top: 25px;
    width: 400px;
    height: 180px;
    border-radius: 5px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    padding: 10px 10px;
    position: relative;
    display: inline-block;
}
.mainp, .copy-button {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
}
.co-img img {
    width: 100px;
    height: 100px;
}
.vertical {
    border-left: 5px dotted black;
    height: 100px;
    position: absolute;
    left: 40%;
}
.content h2 {
    font-size: 21px;
    margin-left: -20px;
    color: #565656;
    text-transform: uppercase;
}   
.content h1 {
    font-size: 30px;
    color: #565656;
}
.copy-button {
    margin: 12px 0 -5px 0;
    height: 45px;
    border-radius: 4px;
    padding: 0 5px;
    border: 1px solid #e1e1e1;
}
.copy-button input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 15px;
}
.copy-button button {
    padding: 5px 20px;
    background-color: #3531cb;
    color: #fff;
    border: 1px solid transparent;
}
.header-hero {
    position: relative;
    z-index: 5;
    background-position: bottom center;
}
label.field-label {
    color: #fff !important;
}
  
#storeino_checkout .tab img {
   height: 20px;
}

#storeino_checkout input:not(.field.fl input),#storeino_checkout select{
    border-color: #3165e2  !important;
    border-width: 2px !important;
    border-style: solid !important;
}

#storeino_checkout input[type="tel"] {
border-radius: 0px 11px 11px 0px  !important;
}
.wf .my1em .field.isvalid{
    border-color: #3165e2 !important;
    border-width: 2px !important;
    border-style: solid !important;
    padding-top: 14.6px !important;
    padding-bottom: 14px !important;
    border-right: none !important;
    border-radius: 11px 0px 0px 11px !important;
}
.navigate{
    display: inherit;
}
.navigate svg{
    padding-right:2px
}
.url {
    margin: 0 10px !important;
}
.bg_cover {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
}
.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
.header-hero-content {
    padding-top: 45px;
}
.header-hero-content .header-sub-title,.header-hero-content .header-title {
    font-size: 38px;
    font-weight: 300;
    color: #fff;
}
.header-hero-content p{
    color: #fff;
}
#storeino_checkout .password-visibility .eye {
    width: 20px !important;
    right: 5px !important;
}
.header-hero-image {
    padding-top: 45px;
}
.services-logo{
    display: inline-block;
    position: relative;
}
</style>